@import url('https://fonts.googleapis.com/css2?family=Tauri&display=swap');

.ReactCollapse--collapse {
  transition: height 500ms;
}

.boxShadow {
  box-shadow: 3px 3px 10px rgba(201, 198, 198, 0.25);
}

.boxShadow2 {
  box-shadow: 4px 4px 15px rgba(236, 236, 236, 0.25);
}

.highcharts-credits{
  display: none;
}